@defer (when dataLoaded) {
  <p-table
    [value]="processList"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="processList-session"
    [paginator]="true"
    [rows]="20"
    rowGroupMode="rowspan"
    groupRowsBy="client.id"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
    [(selection)]="selectedProcess"
  >
  <ng-template pTemplate="header">
    <tr>
      @if(cliId == '') {
        <th class="text-center" pSortableColumn="client.id">{{ 'Licence' | transloco }} <p-sortIcon field="client.id"></p-sortIcon></th>
      }
      <th class="text-center" pSortableColumn="tpr_name_clean">{{ 'Nom du process' | transloco }}<p-sortIcon field="tpr_name_clean"></p-sortIcon></th>
      @if(canWrite()) {
        <th class="text-center">Actions</th>
      }
    </tr>
   <tr>
    @if(cliId == '') {
      <th>
          <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="licences"
                [placeholder]="'Toutes' | transloco"
                (onChange)="filter($event.value)"
                optionLabel="name"
                optionValue="id"
                [showToggleAll]="false"
                class="liste-recherche-table"
              >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
      </th>
    }
    <th>
    <p-columnFilter type="text" field="tpr_name_clean" matchMode="contains"></p-columnFilter>
    </th>
    @if(canWrite()) {
    <th></th>
    }
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
      let-rowgroup="rowgroup"
      let-rowspan="rowspan"
      let-process>
  <tr>
    @if(cliId == '') {
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan"
          [ngClass]="getGroupClass()">
          {{ process.client?.name }}
      </td>
    }
    <td>{{ process.tpr_name_clean }}</td>

    @if(canWrite()) {
      <td class="flex align-items-center justify-content-center gap-3">       
        <a (click)="updateTheProcess(process)" title="{{ 'Modifier le process' | transloco}}">
            <i class="pi pi-pencil"></i>
        </a>
        
        <a (click)="goToParamProcess(process)" title="{{ 'Paramètres du process' | transloco}}">
            <i class="pi pi-cog"></i>
        </a>
        
        <a (click)="outputSettings(process)" title="{{ 'Outputs du process' | transloco}}">
            <i class="pi pi-print"></i>
        </a>

        <a (click)="copyProcess(process);showDialog(process)" title="{{ 'Copier le process' | transloco}}">
            <i class="pi pi-clone"></i>
        </a>
        
        @if(process.tpr_hidden == 0) {
          <a (click)="hideTheProcess(process, 1)" title="{{ 'Masquer le process' | transloco}}">
              <i class="pi pi-eye"></i>
          </a>
        } @else {
          <a (click)="hideTheProcess(process, 0)" title="{{ 'Afficher le process' | transloco}}">
              <i class="pi pi-eye-slash"></i>
          </a>
        }
        
        <a (click)="deleteTheProcess(process)" title="{{ 'Supprimer le process' | transloco}}">
            <i class="pi pi-trash"></i>
        </a>
      </td>
      }
</tr>
</ng-template>
</p-table>

<div class="card flex justify-content-center">
<p-dialog
header="Header"
[(visible)]="visible"
[modal]="true"
[style]="{ width: '40rem', height: '40rem' }">
<ng-template pTemplate="header">
<div class="inline-flex align-items-center justify-content-center gap-2">
<span class="font-bold white-space-nowrap">
Copie du process {{ process_name }}
</span>
</div>
</ng-template>
<span class="p-text-secondary block mb-5">
Sélectionner les licences cibles
</span>

<div class="flex align-items-center gap-3 mb-5">
<p-multiSelect
[(ngModel)]="selectedLicences"
scrollHeight="250px"
[options]="licences"
[placeholder]="'Toutes' | transloco"
(onChange)="onLicenceChange($event)"
optionLabel="name"
optionValue="id"
class="liste-recherche-table"
>
</p-multiSelect>
</div>
  <ng-template pTemplate="footer">
    <button
      pButton
      [label]="'Annuler' | transloco"
      icon="pi pi-minus"
      class="p-button-unactivate"
      (click)="visible = false"
    ></button>
    <button
      pButton
      [label]="'Enregistrer' | transloco"
      [disabled]="!enabledSave"
      icon="fa-solid fa-gear"
      class="p-button-success"
      (click)="copyProcessOnTargets()"
    ></button>
  </ng-template>
</p-dialog>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

