import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ProcessList } from '../../models/process-list';
import { TrackingProcess } from '../../models/tracking-process';


@Injectable({
  providedIn: 'root',
})
export class ApiProcessListService {
  constructor(private http: HttpClient) {}

  getProcessList(clientId:string = ''): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_process_list.php?cliId='+clientId)
      .pipe(
        map((response: any[]) => {
          return response.map((dataProcessList) => {
            return new ProcessList(
              parseInt(dataProcessList.tpr_id),
              dataProcessList.tpr_name_clean,
              dataProcessList.tpr_stats,
              dataProcessList.tpr_stats_treatments,
              dataProcessList.tpr_stats_lots,
              dataProcessList.tpr_stats_photos,
              dataProcessList.tpr_auto_close,
              dataProcessList.tpr_hidden,
              dataProcessList.tpr_flag,
              new Client(
                parseInt(dataProcessList.tpr_cli_id),
                dataProcessList.cli_name)
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  deleteProcess(tpr_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_process.php?tpr_id=' +
      tpr_id
    );
  }

  hideProcess(tpr_id: number, processHidden: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/hide_process.php?tpr_id=' +
      tpr_id + '&processHidden='+ processHidden
    );
  }

  getProcess(tprId:number): Observable<TrackingProcess> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_process_detail.php?tprId=' +
          tprId
      )
      .pipe(
        map((dataProcess: any) => {
          return new TrackingProcess(
            parseInt(dataProcess.tpr_id),                            // tprId
            parseInt(dataProcess.tpr_cli_id),                        // tprCliId
            dataProcess.tpr_name,                                    // tprName
            dataProcess.tpr_comment ?? '', // tprComment
            parseInt(dataProcess.tpr_order),                         // tprOrder
            dataProcess.tpr_picto_path,                              // tprPictoPath
            parseInt(dataProcess.tpr_min_nb_lots_in),                // tprMinNbLotsIn
            parseInt(dataProcess.tpr_min_nb_lots_out),               // tprMinNbLotsOut
            dataProcess.tpr_max_nb_lots_in ? parseInt(dataProcess.tpr_max_nb_lots_in) : null,   // tprMaxNbLotsIn
            dataProcess.tpr_max_nb_lots_out ? parseInt(dataProcess.tpr_max_nb_lots_out) : null, // tprMaxNbLotsOut
            dataProcess.tpr_max_delay ? parseInt(dataProcess.tpr_max_delay) : null,             // tprMaxDelay
            parseInt(dataProcess.tpr_stock_decrease),                // tprStockDecrease
            parseInt(dataProcess.tpr_from_recipe),                   // tprFromRecipe
            parseInt(dataProcess.tpr_from_launcher),                 // tprFromLauncher
            parseInt(dataProcess.tpr_from_treatment),                // tprFromTreatment
            parseInt(dataProcess.tpr_dlc_type),                      // tprDlcType
            dataProcess.tpr_dlc_formula ? dataProcess.tpr_dlc_formula : null, // tprDlcFormula
            parseInt(dataProcess.tpr_dlc_extend),                    // tprDlcExtend
            parseInt(dataProcess.tpr_fifo),                          // tprFifo
            parseInt(dataProcess.tpr_block_lots_in),                 // tprBlockLotsIn
            parseInt(dataProcess.tpr_block_lots_out),                // tprBlockLotsOut
            dataProcess.tpr_delete_day ? parseInt(dataProcess.tpr_delete_day) : null, // tprDeleteDay
            parseInt(dataProcess.tpr_hidden),                        // tprHidden
            dataProcess.tpr_flag ? dataProcess.tpr_flag : null,      // tprFlag
            parseInt(dataProcess.tpr_copy_lots_in_out),              // tprCopyLotsInOut
            parseInt(dataProcess.tpr_display_in_stock),              // tprDisplayInStock
            parseInt(dataProcess.tpr_display_in_dlc),                // tprDisplayInDlc
            parseInt(dataProcess.tpr_display_popup_extend_dlc),      // tprDisplayPopupExtendDlc
            parseInt(dataProcess.tpr_auto_close),                     // tprAutoClose
            parseInt(dataProcess.tpr_auto_close_lots_with_dlc),
            parseInt(dataProcess.tpr_display_lot_in_table)
          );
        })
      );
  }

  postProcess(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_process.php',
      formData
    );
  }
}
